const MapboxLanguage = require("@mapbox/mapbox-gl-language")

class Mapbox {
  constructor(config) {
    this.language = config.language;
    this.accessToken = config.accessToken;
  }

  show(containerId) {
    import("mapbox-gl").then((module) => {
      const mapboxgl = module.default;
      mapboxgl.accessToken = this.accessToken;
      const mapContainer = $(`#${containerId}`);
      const pinImage = mapContainer.attr('data-pin-image');
      const lat = mapContainer.attr('data-lat');
      const lng = mapContainer.attr('data-lng');
      const map = new mapboxgl.Map({
        container: mapContainer[0],
        style: 'mapbox://styles/mapbox/light-v9',
        zoom: 12,
        center: [lng, lat]
      });
      map.scrollZoom.disable();
      map.addControl(new mapboxgl.NavigationControl(), 'top-right');
      map.addControl(new MapboxLanguage({
        supportedLanguages: [this.language],
        defaultLanguage: this.language
      }));
      const marker = document.createElement('div');
      marker.className = 'map-marker';
      marker.style.backgroundImage = 'url("' + pinImage + '")';
      new mapboxgl.Marker(marker)
                  .setLngLat([lng, lat])
                  .addTo(map);
    });
  }
}

module.exports = Mapbox;
