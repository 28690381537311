require("@rails/ujs").start()
require("turbolinks").start()

const Mapbox = require("lib/mapbox")

const mapboxConfig = () => ({
  language: $('html').attr('lang').split('-')[0],
  accessToken: $('meta[name="mapbox-access-token"]').attr('content')
});

module.exports = (localization) => {
  $(document).on('turbolinks:load', () => {
    import("bootstrap").then(() => {
      localization().then((localized) => {
        import("./pages").then((pages) => {
          const mapbox = new Mapbox(mapboxConfig());

          pages.default({ mapbox: mapbox, localized: localized });
        });
      });
    });
  });
}
